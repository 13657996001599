<template>
  <div v-if="isOpen">
    <b-modal
      centered
      :title="isUpdate ? 'Update Mission Reward' : 'Create Mission Reward'"
      v-model="isOpen"
      id="modal-xl"
      size="lg"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <validation-provider
              name="Reward type"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-row class="pb-2">
                <b-col md="3">
                  <label>Reward type</label>
                </b-col>
                <b-col md="9">
                  <b-form-input
                    v-model="model.type"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-col>
              </b-row>
            </validation-provider>
          </b-form>

          <b-form @submit.stop.prevent="handleSubmit()">
            <validation-provider
              name="Reward image url"
              v-slot="validationContext"
              :rules="{ required: true, url: true }"
            >
              <b-row class="pb-2">
                <b-col md="3">
                  <label>Reward image url</label>
                </b-col>
                <b-col md="9">
                  <b-form-input
                    v-model="model.image"
                    type="url"
                    :state="getValidationState(validationContext)"
                  />
                  <b-img v-if="model.image" class="mt-2" :src="model.image" fluid-grow alt="reward image" />
                  <b-form-invalid-feedback id="input-category-name-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-col>
              </b-row>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button
          :disabled="!!isLoading"
          type="submit"
          variant="primary"
          @click="onSave"
        >
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import defaultObject from "../../defaultObject.js";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";
import { url } from '@/@core/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
      selectRewards: [
        {
          text: "Gem",
          value: "GEM",
        },
        {
          text: "Token",
          value: "TOKEN",
        },
      ],
    };
  },

  watch: {
    'model.type'(val) {
      this.model.type = val.toUpperCase()
    }
  },
  
  methods: {
    openCreateModal() {
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal(missionReward) {
      this.model = missionReward;
      this.isOpen = true;
      this.isUpdate = true;
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isOpen = true;
        this.isLoading = true;
        if (!this.isUpdate) {
          try {
            let response = await service.create(this.model);
            if (response.data.type === "DATA") {
              this.$store.dispatch("pushSuccessNotify", {
                text: "Mission reward created!",
              });
            } else {
              this.$store.dispatch("pushErrorNotify", {
                text: "Error created mission reward!",
              });
            }
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          try {
            await service.update({ data: JSON.stringify(this.model) });
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.$emit("loadData");
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.$emit("loadData");
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
